<template>
    <!-- 商家账号审核 -->
    <div class="box-card" style="text-align: left;margin-top: 0;">
        <div class="title">账号信息</div>
        <el-divider></el-divider>
        <div class="content_box">
            <el-form label-width="120px" class="left_box">
                <el-form-item label="商家号：">{{ detailForm.merchant_number }}</el-form-item>
                <el-form-item label="商家名称：">{{ detailForm.merchant_name }}</el-form-item>
                <el-form-item label="负责人：">{{ detailForm.principal }}</el-form-item>
                <el-form-item label="手机号：">{{ detailForm.phone }}</el-form-item>
                <el-form-item label="身份证：">{{ detailForm.idcard }}</el-form-item>
                <el-form-item label="所在地址：">
                    {{ detailForm.address }}
                    <lbButton type="warning" @click="openMap">查看地图</lbButton>
                </el-form-item>
                <el-form-item label="经营范围：">{{ detailForm.business_range }}</el-form-item>
                <el-form-item label="停车场：">{{ detailForm.parking_name }}</el-form-item>
            </el-form>
            <div class="right_box">
                <el-image fit="contain" :src="detailForm.business_license"
                    :preview-src-list="[detailForm.business_license]" :z-index="9999">
                </el-image>
            </div>
        </div>
        <div class="title" style="margin-top: 50px;">审核记录</div>
        <el-divider></el-divider>
        <el-table class="tablestyle" :data="detailForm.audit_log" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="audit_name" label="账号"></el-table-column>
            <el-table-column label="申请时间">
                <template slot-scope="scope">
                    {{ $moment(scope.row.apply_time).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
            </el-table-column>
            <el-table-column prop="audit_name" label="审核人"></el-table-column>
            <el-table-column label="审核时间">
                <template slot-scope="scope">
                    {{ $moment(scope.row.audit_time).format("YYYY-MM-DD HH:mm:ss") }}
                </template>
            </el-table-column>
            <el-table-column label="审核状态">
                <template slot-scope="scope">
                    <span :style="businessStateColor(scope.row.status)"> {{ getWayName('BusinessAutherState',
                        scope.row.status) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="audit_note" label="审核说明"></el-table-column>
        </el-table>
        <!-- 地图标注对话框 -->
        <lebo-dialog append-to-body class="mapDialog" width="50%" title="查看地图"
            :isShow="addressInnerVisible" footerSlot @close="addressInnerVisible = false">
            <div id="amap_box" class="all-amap"></div>
        </lebo-dialog>
        <!-- 拒绝原因对话框 -->
        <lebo-dialog append-to-body width="35%" title="拒绝原因" :isShow="isRefuseDialog"
            footerSlot @close="isRefuseDialog = false">
            <!-- @blur="inspectionItemForm.inspection_content = ((inspectionItemForm.inspection_content.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')" -->
            <el-input type="textarea" maxlength="300" v-model="audit_note" :rows="8" placeholder="请描述拒绝原因"
                @blur="audit_note = ((audit_note.replace(/\r\n|\n|\r/g, '<br/>')).replace(/\s/g, '')).replace(/<br\/>/g, '\n')"></el-input>
            <span slot="footer" class="dialog-footer">
                <!-- <lbButton type="goBack" icon="back"  @click="isRefuseDialog=false">返 回</lbButton> -->
                <lbButton type="default" fill icon="confirm" @click="fnauditBusiness(3)">保 存</lbButton>
            </span>
        </lebo-dialog>
        <!-- <lbButton type="goBack" icon="back" class="checkButton3" @click="defaultForm" v-if="isCheck===1">返 回</lbButton> -->
        <lbButton type="warning" icon="close" class="checkButton2" v-if="detailForm.status == 2" @click="isRefuseDialog = true">拒 绝</lbButton>
        <lbButton type="default" fill icon="confirm" class="checkButton" v-if="detailForm.status == 2"
            @click="fnauditBusiness(1)">同 意</lbButton>
    </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from 'vue-amap'
import { getBusinessAuditList, getBusinessAuditDetail, auditBusiness } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id', 'isCheck'],
  data () {
    return {
      detailForm: {},
      // 是否展示地图
      addressInnerVisible: false,
      // 地图对象
      map: {},
      // 展示拒绝原因对话框
      isRefuseDialog: false,
      audit_note: ''
    }
  },
  created () {
    console.log(this.id)
    this.fngetBusinessAuditDetail()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList', 'getUserInfo'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    businessStateColor (status) {
      // 1.同意 2.待审核 3.已拒绝  0：全部
      switch (status) {
        case 1: return 'color:#67c23a;'
        case 2: return 'color:#e6a23c;'
        case 3: return 'color:#ff0000;'
      }
    },
    async fngetBusinessAuditDetail () {
      const res = await getBusinessAuditDetail({ _id: this.id })
      console.log(res)
      this.detailForm = res && res.Code === 200 ? res.Data : {}
      console.log(this.detailForm)
      // address: "csc"
      // audit_log: []
      // business_license: "https://lebopark.oss-cn-shenzhen.aliyuncs.com/face/b7418151-5401-465b-8c6a-1633fa5865db.jpg"
      // business_range: "aaaaa"
      // idcard: "440482199412062243"
      // latitude: "22"
      // longitude: "11"
      // merchant_name: "Yppn"
      // merchant_number: "ypp2"
      // parking_name: "rbwrwe"
      // phone: "13142319028"
      // principal: "欧派"
      // valid_time: "0001-01-01T00:00:00+08:05"
      // _id: "1f15151b84c34741bc4ec34c"
    },
    // 打开地图对话框
    openMap () {
      this.addressInnerVisible = true
      console.log(this.detailForm)
      var that = this
      this.$nextTick(() => {
        that.initMap()
      })
    },
    // 初始化地图
    initMap () {
      const that = this
      console.log(this.detailForm)
      // 在定制化程度较高的项目中，开发者可能只想通过 vue-amap 引入高德地图，而部分实例化的操作直接基于高德地图的 sdk 完成。这个时候就需要 lazyAMapApiLoaderInstance
      lazyAMapApiLoaderInstance.load().then(() => {
        // 构造一个地图对象
        that.map = new AMap.Map('amap_box', {
          // center: [this.detailForm.longitude, this.detailForm.latitude],
          zoom: 12
        })
        that.map.clearMap()
        // 获取一个标记点
        var marker = new AMap.Marker({
          map: this.map,
          position: [this.detailForm.longitude, this.detailForm.latitude]
        })
        that.map.setCenter([this.detailForm.longitude, this.detailForm.latitude])
        var divInfo = document.createElement('div')
        divInfo.className = 'divInfo'
        divInfo.innerHTML = '地址：' + that.detailForm.address
        // 创建信息窗体
        var infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          content: divInfo // 使用默认信息窗体框样式，显示信息内容
        })
        // 鼠标点击marker弹出自定义的信息窗体
        AMap.event.addListener(marker, 'click', function () {
          infoWindow.open(that.map, marker.getPosition())
        })
        infoWindow.open(that.map, marker.getPosition())
      })
    },
    async fnauditBusiness (status) {
      var obj = {
        _id: this.id,
        status: status
      }
      if (status === 3) obj.audit_note = this.audit_note
      const res = await auditBusiness(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped lang="less">
.title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #000;
}

.content_box {
    display: flex;
    justify-content: space-between;

    /deep/.left_box {
        width: 60%;

        .el-form-item {
            margin-bottom: 0;
        }
    }

    .right_box {
        width: 20%;
        max-height: 250px;
        text-align: right;

        .el-image {
            height: 100%;
        }
    }
}

.tablestyle {
    /deep/ .el-table__body-wrapper {
        max-height: 180px;
    }
}

.mapDialog {
    /deep/ .el-dialog__body {
        padding: 0;
        padding-top: 10px;
    }
}

#amap_box {
    width: 100%;
    height: 500px;

    /deep/.amap-maps {
        .amap-drags {
            .amap-overlays {
                .amap-info {
                    .amap-info-contentContainer {
                        .amap-info-content {
                            background: blanchedalmond;

                            .infodiv {
                                display: flex;
                                width: 150px;
                                flex-direction: column;
                                align-items: flex-start;

                                .input-btn {
                                    background: #409eff;
                                    margin-top: 10px;
                                    color: #ffffff;
                                    font-weight: 400;
                                    border: none;
                                    border-radius: 2px;
                                }
                            }
                        }

                        .amap-info-sharp {
                            bottom: 0;
                            left: 50%;
                            margin-left: -8px;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-top: 8px solid blanchedalmond;
                        }

                        .amap-info-close {
                            position: absolute;
                            top: 5px;
                            right: 5px !important;
                        }
                    }
                }
            }
        }
    }
}

.box-card {
    position: relative;
    padding-bottom: 30px;

    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
    }

    .checkButton3 {
        position: absolute;
        right: 200px;
    }
}</style>
