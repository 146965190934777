<template>
  <!-- 账号审核 -->
  <div class="box-card">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="商家号：">
        <el-input maxlength="30" v-model="queryForm.merchant_number" @keyup.enter.native="onSubmit" placeholder="请输入商家号"
          @input="(e) => (queryForm.merchant_number = validNumberLetter(e))"></el-input>
      </el-form-item>
      <el-form-item label="商家名称：">
        <el-input maxlength="30" v-model="queryForm.merchant_name" @keyup.enter.native="onSubmit" placeholder="请输入商家名称"
          @input="(e) => (queryForm.merchant_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
          placeholder="请选择停车场" @change="onSubmit()">
          <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select popper-class="my-select" clearable v-model="queryForm.status" filterable default-first-option
          placeholder="请选择审核状态" @change="onSubmit()">
          <el-option v-for="item in getOptions('BusinessAutherState')" :key="item.value" :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="账号审核">
      <!-- 主要内容 -->
      <div style="text-align:left;">
        <!-- 表格 -->
        <!-- 使用插件生成表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="status">
            <span :style="businessStateColor(row.status)"> {{ getWayName('BusinessAutherState', row.status) }}</span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="setting" icon="bianji" hint="审核" @click="shopsAudit(row._id, 1)"
              v-if="row.status == 2 && isShowBtn('44c83358b1194a688add6916')"></lbButton>
            <lbButton type="succes" icon="chakan" hint="查看" @click="shopsAudit(row._id, 2)" v-if="row.status != 2">>
            </lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="merchant_number" label="商家号"></el-table-column>
          <el-table-column prop="merchant_name" label="商家名称"></el-table-column>
          <el-table-column prop="principal" label="负责人"></el-table-column>
          <el-table-column prop="phone" label="手机号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <span :style="businessStateColor(scope.row.status)"> {{ getWayName('BusinessAutherState',
                scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="setting" icon="shenhe" hint="审核" @click="shopsAudit(scope.row._id, 1)"
                v-if="scope.row.status == 2 && isShowBtn('44c83358b1194a688add6916')"></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="shopsAudit(scope.row._id, 2)"
                v-if="scope.row.status != 2">></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        <!-- 查看审核账号 -->
        <lebo-dialog append-to-body :title="isCheck === 1 ? '审核账号' : '查看账号'" :isShow="showshopsAudit" width="70%"
          @close="showshopsAudit = false" footerSlot closeOnClickModal>
          <shopsAudit @closeDialog="closeShopsAudit" :id="dialogShopsAuditId" v-if="showshopsAudit" :isCheck="isCheck">
          </shopsAudit>
        </lebo-dialog>
      </div>
    </le-card>
  </div>
</template>
<script>
import { getBusinessAuditList, getBusinessAuditDetail, auditBusiness } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import shopsAudit from './components/lookShopsAudit/index.vue'
export default {
  components: { shopsAudit },
  data () {
    return {
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        parking_id: '',
        merchant_number: '',
        merchant_name: '',
        status: null // 1.同意 2.待审核 3.已拒绝  0：全部
      },
      total: 0,
      // 表格数据
      tableData: [],
      showshopsAudit: false, // 查看账号审核
      dialogShopsAuditId: '',
      isCheck: 1, // 审核1   查看2
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      },
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '商家号',
            prop: 'merchant_number'
          }, {
            label: '商家名称',
            prop: 'merchant_name'
          }, {
            label: '负责人',
            prop: 'principal'
          }, {
            label: '手机号',
            prop: 'phone'
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '审核状态',
            prop: 'status',
            slot: true
          }, {
            label: '申请时间',
            prop: 'create_time',
            slot: true
          }
        ]
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fngetBusinessAuditList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName', 'getAllParkingList', 'getUserInfo'])
  },
  watch: {},
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fngetBusinessAuditList () {
      const res = await getBusinessAuditList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        parking_id: this.queryForm.parking_id,
        merchant_number: this.queryForm.merchant_number,
        merchant_name: this.queryForm.merchant_name,
        status: this.queryForm.status ? this.queryForm.status : 0
      })
      console.log(res)
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    businessStateColor (status) {
      // 1.同意 2.待审核 3.已拒绝  0：全部
      switch (status) {
        case 1: return 'color:#67c23a;'
        case 2: return 'color:#e6a23c;'
        case 3: return 'color:#ff0000;'
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetBusinessAuditList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetBusinessAuditList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetBusinessAuditList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetBusinessAuditList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetBusinessAuditList()
    // },
    shopsAudit (id, flag) {
      this.showshopsAudit = true
      this.dialogShopsAuditId = id
      this.isCheck = flag
    },
    closeShopsAudit () {
      this.showshopsAudit = false
      this.fngetBusinessAuditList()
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}</style>
